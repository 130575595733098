<template>
  <Registrations
    :id="id"
    :title="$t('app.attempts', 2)"
    :endpoint="`${endpoint}.attempts`"
    resource-type="evaluation"
    @view="onViewRedirectToAttempt"
  />
</template>

<script>
import { computed } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
// Composables
import useTexts from "@/composables/useTexts";
import useOptions from "@/composables/useOptions";
import useEvaluation from "@/composables/useEvaluation";
// Components
import Registrations from "@/components/templates/Registrations";

export default {
  components: {
    Registrations
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    resource: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();
    const router = useRouter();

    // Composables
    const { endpoint } = useEvaluation();
    const { getText } = useTexts();
    const { ALL_OPTIONS } = useOptions();

    // Computed
    const documentTitle = computed(() => {
      // eslint-disable-next-line
      const title = `${t("app.attempts", 2)} - ${t("app.observations", 2)} - ${t("app.evaluations", 2)} - ${t("app.teaching")}`;

      const name = getText(props.resource?.texts, "name");
      if (name) {
        return `${name} - ${title}`;
      }

      return title;
    });

    const onViewRedirectToAttempt = item => {
      const types = {
        [ALL_OPTIONS.QUIZ.value]: "quizzes",
        [ALL_OPTIONS.OBSERVATION.value]: "observations"
      };
      const type = types[props.resource?.type];

      router.push({
        name: `teaching-evaluations-${type}-attempts-details`,
        params: {
          id: props.id,
          attemptId: item?.id
        }
      });
    };

    return {
      documentTitle,
      onViewRedirectToAttempt,
      // useEvaluation
      endpoint
    };
  }
};
</script>
